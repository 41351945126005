import gsap, {Power2, Power3} from 'gsap'
import SplitText from "gsap/SplitText"
gsap.registerPlugin(SplitText)

export default {

    data() {
        return {
            mySplitText1: null,
            mySplitText2: null,
            tl1: null,
            tl2: null
        }
    },
    methods: {
        revealTitle() {

            if (!this.isMobile) return;

            if(this.tl1) {
                tl1.pause();
                tl2.pause();
                tl1 = null;
                tl2 = null;
            }

            var $scene = $(this.$refs.scene);
            this.mySplitText1 = new SplitText($scene.find(".title-line1"), { type: "lines", linesClass: 'line-split' });
            this.mySplitText2 = new SplitText($scene.find(".title-line2"), { type: "lines", linesClass: 'line-split' });

            $scene.find('.line-split').each(function (i, el) {
                var curLine = $(el).wrap('<div class="line-split-wrap"></div>');
            })

            var tl1 = gsap.timeline({})
            var tl2 = gsap.timeline({})

            var lines1 = this.mySplitText1.lines;
            tl1.from(lines1, 0.6, { yPercent: 100, ease: Power3.easeInOut, stagger:0.03 }, 0);

            var lines2 = this.mySplitText2.lines;
            tl2.from(lines2, 0.6, { yPercent: 100, ease: Power3.easeInOut, stagger:0.03 }, 0.1);

            tl1.from($scene.find('.title-logo-mark'), .65, { x: -50, opacity: 0, ease: Power2.easeInOut }, 0.2);

        }
    }

}
