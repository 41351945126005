<template>
    <div class="scene scene--5">
        <div class="home__wrap__scene home__wrap__scene5">
            <!-- son image and vector -->
            <div class="son-drawing">
                <img src="/img/home/son-drawings.png" class="son-drawing" alt = "An image of Kratos' son from the God of War franchise">
                <img src="/img/home/son2.png" class="son" alt = "Drawings of Kratos' son from the God of War franchise">
            </div>
            
            <div class="story__quote" v-scroll-reveal="{ desktop: false }">
                <div class="story__quote__copy">{{ data.boy.quote.text }}</div>
                <div class="attribution">{{ data.boy.quote.sourceName }}</div>
                <div class="role">{{ data.boy.quote.sourceTitle }}</div>
            </div>

            <img src="/img/home/son-mobile.jpg" class="son--mobile" v-scroll-reveal="{ desktop: false }" alt = "A mobile-only image of Kratos' son from the God of War franchise">

        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'isMobile'],
        data() {
            return {
                tl: null
            }
        },
        methods: {
            scrollReveal() {

                if (!this.isMobile) return;

                var speed = 0.85;
                var vw = $(window).width();

                this.tl = gsap.timeline({delay:0.2})
                this.tl.timeScale(1.8);

                
            }
        }
    }
</script>