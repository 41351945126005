<template>
    <div class="scene scene--2">
        <div class="home__wrap__scene home__wrap__scene2" v-scroll-reveal="{ viewFactor: 0.5, desktop: false }">
            <img src="/img/home/your-story-begins-here.svg" class="our-journey" alt = "On the left is a decorative arrow that points to words on the right that read 'Your Story. Begins here.'">
            <img src="/img/home/20-Years-Home.svg" class="twenty-years" alt = "An image that reads '20 years of greatness'.">
            <img src="/img/home/kratos.png" class="kratos" alt = "An image of Kratos from the God of War series.">
            <img src="/img/home/son.png" class="son" alt = "An image of Kratos' son from the God of War series.">
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'isMobile'],
        data() {
            return {
                tl: null
            }
        },
        methods: {
            scrollReveal() {
                if (!this.isMobile) return;

                this.tl = gsap.timeline({delay:0.2})
                this.tl.timeScale(1.8);
                this.tl.fromTo('.home__wrap__scene2', 1, { opacity: 0, y:150}, { opacity:1, y:0, force3D:true});
            }
        }
    }
</script>