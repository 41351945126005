<template>
    <section class="home" ref="homeRef" v-if="pageActive">

        <div class="home__wrap" v-if="data">

            <!-- preload images before initing timeline -->
            <image-preloader v-if="preloadingImages" :srcs="[
                    '/img/home/our-journey.svg',
                    '/img/home/kratos.png',
                    '/img/home/son-drawings.png',
                    '/img/home/kratos2.png',
                    '/img/home/kratos-drawings.png',
                ]" @loaded-all="allImagesLoaded" />

            <div class="home__wrap__inner" v-if="data && !preloadingImages">
                <scene1 :data="data" :isMobile="isMobile"/>
                <scene2 :data="data" :isMobile="isMobile"/>
                <scene3 :data="data" :isMobile="isMobile"/>
                <scene34 :data="data" :isMobile="isMobile"/>
                <scene4 :data="data" :isMobile="isMobile"/>
                <scene5 :data="data" :isMobile="isMobile"/>
                <scene6 :data="data" :isMobile="isMobile"/>
                <scene7 :data="data" :isMobile="isMobile"/>
                <scene8 :data="data" :isMobile="isMobile"/>
                <scene9 :data="data" :isMobile="isMobile"/>
            </div>

        </div>

        <transition name="fade">
            <router-view parentName="home" :data="videoModalAssets" />
        </transition>

    </section>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import HomeMixin from '../mixins/HomeTimeline.js'
    import Meta from '../mixins/Meta.js'
    import Slick from 'slick-carousel';
    import Scene1 from '../components/home/scene1.vue'
    import Scene2 from '../components/home/scene2.vue'
    import Scene3 from '../components/home/scene3.vue'
    import Scene34 from '../components/home/scene3-4mobile'
    import Scene4 from '../components/home/scene4.vue'
    import Scene5 from '../components/home/scene5.vue'
    import Scene6 from '../components/home/scene6.vue'
    import Scene7 from '../components/home/scene7.vue'
    import Scene8 from '../components/home/scene8.vue'
    import Scene9 from '../components/home/scene9.vue'

    import gsap, {Power2} from 'gsap'
    import ScrollToPlugin from "gsap/ScrollToPlugin"
    gsap.registerPlugin(ScrollToPlugin)

    export default {
        mixins: [HomeMixin, Meta],
        components: {
            Scene1,
            Scene2,
            Scene3,
            Scene34,
            Scene4,
            Scene5,
            Scene6,
            Scene7,
            Scene8,
            Scene9
        },
        data() {
            return {
                data: null,
                videoModalAssets: null,
                lastScrollTime: Number,
                preloadingImages: true,
                isMobile: Boolean,
                inited: false,
                resizeTimeout: Number,
                pageActive: true,
                tl: null,
                tlDuration: 0,
                targetTlProgress: 0,
                introEndTime: Number
            }
        },
        mounted() {
            this.$body.addClass('is-home');
            this.loadData();
        },
        methods: {
            loadData() {
                axios.get('/data/home.json')
                    .then(response => {
                        this.data = response.data;
                        this.setMetaData(this.data.meta);
                        console.log(this.data);
                        this.videoModalAssets = this.data.godOfWar.trailer.assets;

                        // init everything here, else will be inited when images are done preloading
                        if (!this.inited && !this.preloadingImages) {
                            this.init();
                        }
                        this.$checkAgeGate(this.data.ageGate);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            init() {

                this.inited = true;
                // this.isMobile = this.$window.width() < 768;
                this.isMobile = window.innerWidth < 768;
                
                this.$sectionLoaded();

                // reset things
                if (this.tl) {
                    this.tl.kill();
                    this.tl = null;
                    this.$window.off('scroll');
                }

                if (!this.isMobile) {
                    this.$window.on('scroll', this.onScroll);

                    setTimeout(() => {
                        $('.home__wrap').css({
                            'visibility': 'visible'
                        });
                        this.initTimeline();
                    }, 900);

                } else {
                    setTimeout(()=> {
                        this.$sr.sync();
                    }, 1000);
                    Vue.nextTick(()=> {
                        this.$sr.sync();
                    });
                }
                this.$window.on('resize', this.onResize);
            },
            allImagesLoaded(e) {
                this.preloadingImages = false;
                if (this.data) {
                    this.init();
                }
            },
            onScroll(e) {

                if (this.isMobile) return;

                if (!this.tl || this.tl.time() < this.introEndTime) {
                    gsap.to(window, 0, {
                        scrollTo: 0
                    });
                    return;
                }

                var scrollTop = this.$window.scrollTop();
                var docHeight = $(document).height();
                var winHeight = this.$window.height();
                var scrollbarPercent = Math.max(scrollTop / (docHeight - winHeight), 0);
                let introEndPercent = this.introEndTime / this.tl.duration();
                let totalTimeMinusIntro = this.tl.duration() - this.introEndTime;
                var targetTime = (totalTimeMinusIntro * scrollbarPercent) + this.introEndTime;

                gsap.to(this.tl, 1, {
                    time: targetTime,
                    ease: Power2.easeOut,
                    overwrite: 'all'
                });

            },
            onResize() {

                //return;

                var isIpadPortrait = this.$html.hasClass('ua-mobile-ipad') && window.innerWidth == 768;

                // don't re-init anything if resizing on mobile
                if (this.isMobile && this.$window.width() <= 768 || isIpadPortrait) {
                    return;
                }

                // setting pageActie to false removes section from DOM, then we put back
                // after a tick so all elements return to their original states
                this.pageActive = false;

                clearTimeout(this.resizeTimeout);
                this.resizeTimeout = setTimeout(() => {
                    this.pageActive = true;
                    Vue.nextTick(() => {
                        this.init();
                    });
                }, 500);
            },
        },
        beforeDestroy() {
            gsap.killTweensOf("*")
            this.$body.removeClass('is-home');
            this.$window.off('scroll');
            this.$window.off('resize');
            this.$html.css({ overflow: 'visible' });
             if (this.tl) {
                this.tl.pause();
                this.tl.kill();
                this.tl = null;
            }
        }
    }
</script>