<template>
    <div class="scene scene--7" ref="scene" v-scroll-reveal="{ distance: '0px', beforeReveal: scrollReveal, desktop: false }">
        <div class="home__wrap__scene home__wrap__scene7">
            <div class="our-challenge" v-scroll-reveal.reset="{ distance: '0px', beforeReveal: revealTitle }">
                <h1 class="our-challenge__header title-line1">OUR<br>INGENUITY</h1>
                <h1 class="our-challenge__subheading title-line2">YOUR<br>IMAGINATION</h1>
                <img src="../../assets/global/sms-logo--mark.svg" alt="Santa Monica Studios logo mark" class="our-challenge__logo--mark title-logo-mark">
            </div>
            <div class="scene7__mond">

                <svg class="scene7-mond" enable-background="new 0 0 1001.3 692.9" viewBox="0 0 1001.3 692.9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="scene7MondSVG">
                    <clipPath id="scene7-mond--mask">
                        <path d="m459.5 233.4v231.2c0 62.5-25.3 119-66.3 160s-97.5 66.3-160 66.3h-231.2v-55.1-176.1c0-62.5 25.3-119 66.3-160s97.5-66.3 160-66.3z" />
                    </clipPath>
                    <g clip-path="url(#scene7-mond--mask)">
                        <image class="scene7-mond--image" height="800" overflow="visible" transform="matrix(.5719 0 0 .5719 2 233.15)" width="800" :xlink:href="data.ourChallenge.image" />
                    </g>
                    <path class="scene7-mond--yellow" d="m343.8 232.1h115.7v-113.1h-4.1c-61.6 0-111.6 50-111.6 111.6z" fill="#e0c565" />
                    <path class="scene7-mond--white" d="m343.2 232.1h115.7v115.7h-115.7z" fill="#fff" />

                    <!-- red -->
                    <g style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">
                        <path class="scene7-mond--c3a" d="m343.8 349.1h115 2c31.6 0 60.2-12.8 80.9-33.5s33.5-49.3 33.5-80.9v-1.5-115.5" />
                        <path class="scene7-mond--c3b" d="m575.2 117.7h-114.9-2c-31.6 0-60.2 12.8-80.9 33.5s-33.5 49.3-33.5 80.9v2 114.9" />
                        <path class="scene7-mond--c2a" d="m459.5 2h-116.9c-31.6 0-60.2 12.8-80.9 33.5s-33.5 49.3-33.5 80.9v116.9" />
                        <path class="scene7-mond--c2b" d="m459.5 233.4v-114.4-117" />
                        <path class="scene7-mond--line" d="m459.5 233.5h539.8" />
                        <path class="scene7-mond--c1a" d="m459.5 233.4v231.2c0 62.5-25.3 119-66.3 160s-97.5 66.3-160 66.3h-231.2" />
                        <path class="scene7-mond--c1b" d="m2 690.9v-55.1-176.1c0-62.5 25.3-119 66.3-160s97.5-66.3 160-66.3h231.2" />
                    </g>

                    <!-- tan -->
                    <g style="fill:none;stroke:#dfd9c8;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">
                        <path class="scene7-mond--c3a" d="m343.8 349.1h115 2c31.6 0 60.2-12.8 80.9-33.5s33.5-49.3 33.5-80.9v-1.5-115.5" />
                        <path class="scene7-mond--c3b" d="m575.2 117.7h-114.9-2c-31.6 0-60.2 12.8-80.9 33.5s-33.5 49.3-33.5 80.9v2 114.9" />
                        <path class="scene7-mond--c2a" d="m459.5 2h-116.9c-31.6 0-60.2 12.8-80.9 33.5s-33.5 49.3-33.5 80.9v116.9" />
                        <path class="scene7-mond--c2b" d="m459.5 233.4v-114.4-117" />
                        <path class="scene7-mond--line" d="m459.5 233.5h539.8" />
                        <path class="scene7-mond--c1a" d="m459.5 233.4v231.2c0 62.5-25.3 119-66.3 160s-97.5 66.3-160 66.3h-231.2" />
                        <path class="scene7-mond--c1b" d="m2 690.9v-55.1-176.1c0-62.5 25.3-119 66.3-160s97.5-66.3 160-66.3h231.2" />
                    </g>

                    <!-- black -->
                    <g style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">
                        <path class="scene7-mond--c3a" d="m343.8 349.1h115 2c31.6 0 60.2-12.8 80.9-33.5s33.5-49.3 33.5-80.9v-1.5-115.5" />
                        <path class="scene7-mond--c3b" d="m575.2 117.7h-114.9-2c-31.6 0-60.2 12.8-80.9 33.5s-33.5 49.3-33.5 80.9v2 114.9" />
                        <path class="scene7-mond--c2a" d="m459.5 2h-116.9c-31.6 0-60.2 12.8-80.9 33.5s-33.5 49.3-33.5 80.9v116.9" />
                        <path class="scene7-mond--c2b" d="m459.5 233.4v-114.4-117" />
                        <path class="scene7-mond--line" d="m459.5 233.5h539.8" />
                        <path class="scene7-mond--c1a" d="m459.5 233.4v231.2c0 62.5-25.3 119-66.3 160s-97.5 66.3-160 66.3h-231.2" />
                        <path class="scene7-mond--c1b" d="m2 690.9v-55.1-176.1c0-62.5 25.3-119 66.3-160s97.5-66.3 160-66.3h231.2" />
                    </g>


                </svg>

                <p class="home__wrap__scene7__copy" v-html="data.ourChallenge.copy"></p>
                <div class="home__wrap__scene7__openings">
                    <span class="num-openings">{{ data.ourChallenge.numOpenings }}</span>
                    <br>
                    OPENINGS
                </div>

                <div class="cta-btn mobile-only" v-scroll-reveal="{ desktop: false }">
                    <router-link to="/careers">
                        <div class="btn btn--red-outline">
                            <span>APPLY TODAY</span>
                        </div>
                    </router-link>
                </div>

            </div>
            <div class="scene7__right">
                <div class="scene4__right__quote">

                </div>
                <div class="scene7__right__button" >
                    <router-link to="/careers">
                        <diamond-button>APPLY TODAY</diamond-button>
                    </router-link>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import DiamondButton from '../buttons/DiamondButton';
    import HomeTitleReveal from '../../mixins/HomeTitleReveal.js'
    import gsap, {Power3} from 'gsap'
    import DrawSVGPlugin from "gsap/DrawSVGPlugin"
    gsap.registerPlugin(DrawSVGPlugin)

    export default {
        mixins:[HomeTitleReveal],
        props: ['data', 'isMobile'],
        components: {
            DiamondButton
        },
        data() {
            return {
                tl: null
            }
        },
        methods: {
            scrollReveal() {

                if (!this.isMobile) return;

                var speed = 0.85;
                var vw = $(window).width();

                this.tl = gsap.timeline({delay:0.2})
                this.tl.timeScale(1.8);

                var scene7MondStagger = 0.15;

                this.tl.fromTo($('.scene7-mond--c1a'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0);
                this.tl.fromTo($('.scene7-mond--c1b'), 2, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0);
                this.tl.fromTo($('.scene7-mond--c2a'), 1.1, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0.7);
                this.tl.fromTo($('.scene7-mond--c2b'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0);
                this.tl.fromTo($('.scene7-mond--c3a'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0);
                this.tl.fromTo($('.scene7-mond--c3b'), 2, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0);
                this.tl.fromTo($('.scene7-mond--line'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0);
                this.tl.from($('.scene7-mond--yellow'), 2, { opacity: 0, ease: Power3.easeInOut , stagger: scene7MondStagger}, 0.8);
                this.tl.from($('.scene7-mond--white'), 2, { opacity: 0, ease: Power3.easeInOut , stagger: scene7MondStagger}, 0.8);
                this.tl.from($('.scene7-mond--image'), 2, { opacity: 0, scale: 0.7, transformOrigin: '50% 50%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0.8);


            }
        }
    }
</script>
