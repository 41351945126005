import gsap, {Linear, Power1, Power2, Power3} from 'gsap'
import DrawSVGPlugin from "gsap/DrawSVGPlugin"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import SplitText from "gsap/SplitText"
gsap.registerPlugin(DrawSVGPlugin)
gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(SplitText)

export default {

    methods: {
        initTimeline() {

            var vw = $(window).width();
            var speed = 0.85;
            var scenes = ['.scene--1', '.scene--2', '.scene--3', '.scene--4', '.scene--5', '.scene--6', '.scene--7', '.scene--8', '.scene--9'];

            // set left position of each scene based on previous scenes' widths
            for (var i = 0; i < scenes.length; i++) {
                var curScene = scenes[i];
                if (i == 0) {
                    var left = 0;
                } else {
                    var $prevScene = $(scenes[i - 1]);
                    var width = $prevScene.outerWidth();
                    var left = width + parseInt($prevScene.css('left'));
                }
                //gsap.set(curScene, { left: left });
            }

            var totalWidth = $('.home__wrap__inner').outerWidth();

            // change all scenes to run position absolute for better performance
            for (var i = 0; i < scenes.length; i++) {
                var $curScene = $(scenes[i]);
                //gsap.set($curScene, { position: 'absolute', width: $curScene.outerWidth(), height: $curScene.outerHeight() });
            }

            // return;

            this.tl = gsap.timeline({ autoRemoveChildren: false });
            this.tl.timeScale(1.8);



            /*--------------------- SCENE 1 ---------------------- */

            // Mondrian
            this.tl.fromTo($('.home__wrap__scene1__a').find('.mond'), speed * 2,
                { x: -50, force3D: true },
                { x: 0, ease: Power2.easeInOut },
                0);

            // scene 1 Mondrian animation
            let stagger = 0.3;
            this.tl.fromTo($('.m-right-line'), 2, { drawSVG: '22% 22%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger}, 0);
            this.tl.fromTo($('.m-right-line'), 0.4, { opacity: 0 }, { opacity: 1 , stagger: stagger}, 0);
            this.tl.fromTo($('.m-bottom-line'), 2, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger}, 0);
            this.tl.fromTo($('.m-bottom-line'), 0.4, { opacity: 0 }, { opacity: 1 , stagger: stagger}, 0);
            this.tl.fromTo($('.m-white-curve'), 1.2, { drawSVG: '0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger}, 1);
            this.tl.fromTo($('.m-yellow-curve'), 1.2, { drawSVG: '0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger}, 1);
            this.tl.fromTo($('.m-white-leg'), 1.2, { drawSVG: '0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger}, 1);
            this.tl.fromTo($('.m-dot1'), 0.3, { opacity: 0 }, { opacity: 1, ease: Power3.easeInOut , stagger: stagger}, 1.55);
            this.tl.fromTo($('.m-dot2'), 0.3, { opacity: 0 }, { opacity: 1, ease: Power3.easeInOut , stagger: stagger}, 1.45);
            this.tl.fromTo($('.m-dot3'), 0.3, { opacity: 0 }, { opacity: 1, ease: Power3.easeInOut , stagger: stagger}, 1.35);
            this.tl.fromTo($('.m-top-curve'), 1.1, { drawSVG: '0%' }, { drawSVG: '100%', ease: Power2.easeInOut , stagger: stagger}, 1.6);
            this.tl.fromTo($('.m-yellow-fill'), 1.3, { opacity: 0 }, { opacity: 1 }, "-=1.7");
            this.tl.from($('.m-image'), 1.3, { opacity: 0, scale: 0.64, transformOrigin: '50% 50%', ease: Power2.easeInOut }, "-=1.7");

            // Watch Trailer
            this.tl.fromTo('.watch-trailer', speed,
                { x: vw / 6, opacity: 0, force3D: true },
                { x: 0, opacity: 1, ease: Power2.easeInOut }, '-=0.2');

            // Apply button
            this.tl.fromTo($('.home__wrap__scene1__b').find('.cta-btn'), speed,
                { x: vw / 6, opacity: 0, force3D: true },
                { x: 0, opacity: 1, ease: Power2.easeInOut },
                '-=' + ((speed * 0.8) + 0));

            // Cutting edge
            this.tl.fromTo($('.home__wrap__scene1__b').find('h3'), speed,
                { x: vw / 6, opacity: 0, force3D: true },
                { x: 0, opacity: 1, ease: Power2.easeInOut },
                '-=' + (speed * 0.97));

            // God of War
            this.tl.fromTo($('.home__wrap__scene1__b').find('h2'), speed,
                { x: vw / 6, opacity: 0, force3D: true },
                { x: 0, opacity: 1, ease: Power2.easeInOut },
                '-=' + (speed * 0.97));

            // The creators of
            this.tl.fromTo($('.home__wrap__scene1__b').find('h5'), speed,
                { x: vw / 6, opacity: 0, force3D: true },
                { x: 0, opacity: 1, ease: Power2.easeInOut },
                '-=' + (speed * 0.97));



            /*--------------------- SCENE 2 ---------------------- */

            this.tl.from($('.home__wrap__scene2'), speed,
                { x: "+=500", opacity: 0, force3D: true, ease: Power2.easeInOut }, '-=' + (speed * 0.97));

            // store timeline percent after intro's over
            this.tl.call(() => {
                this.targetTlProgress = this.tl.progress();
                // scrolling to 1 instead of 0 fixes weird safari jump/glitch
                gsap.to(window, 0, { scrollTo: 1 });
            }, null, this);

            // stop and wait for user scroll
            this.tl.addLabel("introOver");
            this.tl.addPause();

            var isEdge = this.$html.hasClass('ua-edge');
            var $scene3MondSVG = $('#scene3MondSVG');
            var $scene3Mond = $('.scene3__mond');

            var $scene7MondSVG = $('#scene7MondSVG');
            var $scene7Mond = $('.scene7__mond');

            var scene3Mond = gsap.timeline({
                paused: true, onUpdate: () => {
                    if(isEdge) {
                        $scene3Mond.width($scene3MondSVG.width());
                    }
                }
            });

            var scene3Title = gsap.timeline({ paused: true });
            var scene4Mond = gsap.timeline({ paused: true });
            var scene4SVG = gsap.timeline({ paused: true });
            var scene5SVG = gsap.timeline({ paused: true });
            var scene7Mond = gsap.timeline({
                paused: true, onUpdate: () => {
                    if(isEdge) {
                        $scene7Mond.width($scene7MondSVG.width());
                    }
                }
            });

            // trigger scene 3 mondrian to reset
            //this.tl.call(() => { scene3Mond.pause(0); }, null, this, 'introOver+=0.1');

            var numScenes = 8;
            var timePerScene = 2;
            var tweenTime = numScenes * timePerScene;

            this.tl.to('.scene', tweenTime, { x: '-=' + (totalWidth - vw), force3D: true, ease: Linear.easeNone }, 'introOver');


            // Scene 1 (God of War)
            this.tl.to($('.watch-trailer'), 1, { x: vw * -.1, force3D: true, ease: Power1.easeInOut }, 'introOver');
            this.tl.to($('.mond'), 1, { x: vw * -.1, force3D: true, ease: Power1.easeInOut }, 'introOver+=0.2');
            this.tl.to($('.home__wrap__scene1__b'), 1, { x: vw * -.1, force3D: true, ease: Power1.easeInOut }, 'introOver+=0.4');

            // Scene 2 (kratos and son)
            this.tl.to($('.home__wrap__scene2'), timePerScene, { x: vw * -.1, force3D: true, ease: Power1.easeInOut }, 'introOver+=' + (timePerScene * 0));
            this.tl.from($('.son'), timePerScene * 1.2, { x: -55, force3D: true, ease: Power1.easeInOut }, 'introOver+=' + (timePerScene * 0));
            this.tl.from($('.twenty-years'), timePerScene * 1, { x: -35, force3D: true, ease: Power1.easeInOut }, 'introOver+=' + (timePerScene * 0));


            // shrink logo
            this.tl.to($('.header__logo__text'), 0.4, { opacity: 0 }, 'introOver');
            this.tl.to($('.header__logo a'), 0.4, { scale: 0.65, x: -30, y: window.innerWidth < 1050 ? -30 : -45, force3D: true }, 'introOver');

            // Scene 3 (our legacy your strength)
            this.tl.to($('.home__wrap__scene3'), 2.5, { x: vw * -.1, ease: Power1.easeInOut }, 'introOver+=' + 1);

            // scene 3 quote
            var scene3Quote = gsap.timeline({ paused: true });
            scene3Quote.from($('.scene3__right__quote'), 1.2, { opacity: 0, x: 170, force3D: true, ease: Power3.easeInOut });
            this.tl.call(() => { scene3Quote.play(); }, null, this, 'introOver+=' + 2.1);

            // reset scene 4 mondrian
            //this.tl.call(() => { scene4SVG.pause(0); }, null, this, 'introOver+=2.1');

            // scene 3 title
            this.addTitleAnimation($('.scene--3'), 1.0);

            // Scene 3 (our legacy your strength) Mondrian
            var stagger2 = 0.15;
            //if(!this.$html.hasClass('ua-edge')) {
            scene3Mond.timeScale(1.7);
            scene3Mond.fromTo($('.s3-top-curve1'), 2, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger2}, 0);
            scene3Mond.fromTo($('.s3-top-curve2'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger2}, 0);
            scene3Mond.fromTo($('.s3-bottom-line'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger2}, 0);
            scene3Mond.fromTo($('.s3-bottom-curve1'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger2}, 0);
            scene3Mond.fromTo($('.s3-bottom-curve2'), 2, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: stagger2}, 0);
            scene3Mond.from($('.s3-image1'), 2, { opacity: 0, scale: 0.76, transformOrigin: '50% 50%', ease: Power3.easeInOut , stagger: stagger2}, 1.3);
            scene3Mond.from($('.s3-image2'), 2, { opacity: 0, scale: 0.37, transformOrigin: '50% 50%', ease: Power3.easeInOut , stagger: stagger2}, 1.3);
            scene3Mond.from($('.s3-yellow-box'), 0.65, { opacity: 0, ease: Power3.easeInOut , stagger: stagger2}, 1.2);
            scene3Mond.fromTo($('.s3-white-line1'), 0.4, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeIn , stagger: stagger2}, 0.7);
            scene3Mond.fromTo($('.s3-white-line2'), 0.2, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Linear.easeNone , stagger: stagger2}, 1.1);
            scene3Mond.fromTo($('.s3-small-curve'), 0.25, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeOut , stagger: stagger2}, 1.3);
            scene3Mond.fromTo($('.s3-yellow-line1'), 0.2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Linear.easeNone , stagger: stagger2}, 1.1);
            scene3Mond.fromTo($('.s3-yellow-line2'), 0.25, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeOut , stagger: stagger2}, 1.3);
            this.tl.call(() => { scene3Mond.play(); }, null, this, 'introOver+=1.4');
            //}

            // Scene 4 Kratos svg animation
            scene4SVG.from($('.kratos-drawing').find('.kratos'), 1.15, { scale: 0.5, opacity: 0, transformOrigin: '70% 95%', ease: Power3.easeInOut }, 0.25);
            this.tl.call(() => { scene4SVG.play(); }, null, this, 'introOver+=3.1');

            // scene 4 title
            this.addTitleAnimation($('.scene--4'), 4.2);


            // Scene 4 Mondrian
            var scene4MondStagger = 0.15;
            scene4Mond.timeScale(1.7);
            scene4Mond.fromTo($('.scene4__mond--cl'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene4MondStagger}, 0);
            scene4Mond.fromTo($('.scene4__mond--cr1'), 1.6, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeOut , stagger: scene4MondStagger}, 0.7);
            scene4Mond.fromTo($('.scene4__mond--cr2'), 1.55, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene4MondStagger}, 0);
            scene4Mond.fromTo($('.scene4__mond--bline'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene4MondStagger}, 0);
            scene4Mond.fromTo($('.scene4__mond--bcurve1'), 2, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene4MondStagger}, 0);
            scene4Mond.fromTo($('.scene4__mond--bcurve2'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene4MondStagger}, 0);
            scene4Mond.fromTo($('.scene4__mond--bcurve3'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene4MondStagger}, 0);
            scene4Mond.from($('.scene4__mond--red'), 2, { opacity: 0, ease: Power3.easeInOut , stagger: scene4MondStagger}, 1);
            scene4Mond.from($('.scene4__mond--yellow'), 2, { opacity: 0, ease: Power3.easeInOut , stagger: scene4MondStagger}, 1);
            scene4Mond.from($('.scene4__mond--image'), 2, { opacity: 0, scale: 0.7, transformOrigin: '50% 50%', ease: Power3.easeInOut , stagger: scene4MondStagger}, 1);
            //this.tl.call(() => { scene4Mond.pause(0); }, null, this, 'introOver+=4.2');
            this.tl.call(() => { scene4Mond.play(); }, null, this, 'introOver+=4.8');

            // reset son timeline
            //this.tl.call(() => { scene5SVG.pause(0); }, null, this, 'introOver+=4.8');

            // slide entire Scene 4 a bit
            this.tl.to($('.home__wrap__scene4'), 2.5, { x: vw * -.1, ease: Power1.easeInOut }, 'introOver+=' + 3.2);

            // Scene 5 Son svg animation
            scene5SVG.from($('.son-drawing').find('.son'), 1.15, { scale: 0.5, opacity: 0, transformOrigin: '70% 95%', ease: Power3.easeInOut }, 0.6);
            this.tl.call(() => { scene5SVG.play(); }, null, this, 'introOver+=6.2');

            // slide entire Scene 5 (Son) a bit
            this.tl.to($('.home__wrap__scene5'), 2.5, { x: vw * -.1, ease: Power1.easeInOut }, 'introOver+=' + 6.1);

            // scene 5 quote
            var scene5Quote = gsap.timeline({ paused: true });
            scene5Quote.from($('.home__wrap__scene5 .story__quote'), 1.2, { opacity: 0, x: 175, force3D: true, ease: Power3.easeInOut });
            this.tl.call(() => { scene5Quote.play(); }, null, this, 'introOver+=' + 7.2);

            // slide entire Scene 6 (Perks Slider) a bit
            this.tl.to($('.home__wrap__scene6'), 2.5, { x: vw * -.1, ease: Power1.easeInOut }, 'introOver+=' + 8.1);
            // TODO: animate arrows here


            // scene 7 title
            this.addTitleAnimation($('.scene--7'), 10.5);

            // Scene 7 Mondrian
            var scene7MondStagger = 0.15;
            scene7Mond.timeScale(1.7);
            scene7Mond.fromTo($('.scene7-mond--c1a'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0);
            scene7Mond.fromTo($('.scene7-mond--c1b'), 2, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0);
            scene7Mond.fromTo($('.scene7-mond--c2a'), 1.1, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0.7);
            scene7Mond.fromTo($('.scene7-mond--c2b'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0);
            scene7Mond.fromTo($('.scene7-mond--c3a'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0);
            scene7Mond.fromTo($('.scene7-mond--c3b'), 2, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0);
            scene7Mond.fromTo($('.scene7-mond--line'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0);
            scene7Mond.from($('.scene7-mond--yellow'), 2, { opacity: 0, ease: Power3.easeInOut , stagger: scene7MondStagger}, 0.8);
            scene7Mond.from($('.scene7-mond--white'), 2, { opacity: 0, ease: Power3.easeInOut , stagger: scene7MondStagger}, 0.8);
            scene7Mond.from($('.scene7-mond--image'), 2, { opacity: 0, scale: 0.7, transformOrigin: '50% 50%', ease: Power3.easeInOut , stagger: scene7MondStagger}, 0.8);
            //this.tl.call(() => { scene7Mond.pause(0); }, null, this, 'introOver+=10');
            this.tl.call(() => { scene7Mond.play(); }, null, this, 'introOver+=10.85');


            // slide entire Scene 7 (Our Challenge) a bit
            this.tl.to($('.home__wrap__scene7'), 2.5, { x: vw * -.1, ease: Power1.easeInOut }, 'introOver+=' + 9.9);


            // scene 8 title
            this.addTitleAnimation($('.scene--8'), 13);

            // slide entire Scene 8 (Studio News) a bit
            this.tl.to($('.home__wrap__scene8'), 2.5, { x: vw * -.1, ease: Power1.easeInOut }, 'introOver+=' + 12.1);

            // scene 8 title
            var scene8Titles = gsap.timeline({ paused: true });
            scene8Titles.from($('.news-carousel__titles'), 1.2, { opacity: 0, x: 170, force3D: true, ease: Power3.easeInOut });
            this.tl.call(() => { scene8Titles.play(); }, null, this, 'introOver+=' + 13.4);


            // slide entire Scene 9 (Footer) a bit
            this.tl.to($('.home__wrap__scene9'), 2.5, { x: vw * -.1, ease: Power1.easeInOut }, 'introOver+=' + 13.6);

            this.tlDuration = this.tl.duration();
            //this.introEndTime = this.tl.getLabelTime('introOver');
            this.introEndTime = this.tl.labels.introOver

        },
        addTitleAnimation($scene, time) {

            if (!this.isMobile) {

                var mySplitText1 = new SplitText($scene.find('.title-line1'), { type: "lines", linesClass: 'line-split' });
                var mySplitText2 = new SplitText($scene.find('.title-line2'), { type: "lines", linesClass: 'line-split' });

                $scene.find('.line-split').each(function (i, el) {
                    var curLine = $(el).wrap('<div class="line-split-wrap"></div>');
                })

                var tl1 = gsap.timeline({ paused: true });
                var tl2 = gsap.timeline({ paused: true });

                var lines1 = mySplitText1.lines;
                tl1.from(lines1, 0.6, { yPercent: 100, ease: Power3.easeInOut, stagger:0.03 }, 0);

                var lines2 = mySplitText2.lines;
                tl2.from(lines2, 0.6, { yPercent: 100, ease: Power3.easeInOut, stagger:0.03 }, 0.1);

                tl1.from($scene.find('.title-logo-mark'), .65, { x: -50, opacity: 0, ease: Power2.easeInOut }, 0.2);

                this.tl.call(() => {
                    tl1.play();
                    tl2.play();
                }, null, this, 'introOver+=' + time);

            }

        }
    },

}
