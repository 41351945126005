<template>
    <div class="scene scene--8" ref="scene" v-scroll-reveal="{ distance: '0px', beforeReveal: scrollReveal, desktop: false }">
        <div class="home__wrap__scene home__wrap__scene8" v-if="data">
            <div class="studio-news" v-scroll-reveal.reset="{ distance: '0px', beforeReveal: revealTitle }">
                <h1 class="studio-news__header title-line1">FEATURED</h1>
                <h1 class="studio-news__subheading title-line2">STORIES</h1>
            </div>
            <home-news-carousel :data="data.studioNews.stories" v-scroll-reveal="{ desktop: false }" />

            <div class="cta-btn mobile-only" v-scroll-reveal="{ desktop: false }">
                <router-link to="/stories">
                    <div class="btn btn--red-outline">
                        <span>ALL NEWS</span>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import HomeNewsCarousel from './HomeNewsCarousel.vue';
    import HomeTitleReveal from '../../mixins/HomeTitleReveal.js'
    import gsap from 'gsap'
    export default {
        mixins:[HomeTitleReveal],
        props: ['data', 'isMobile'],
        components: {
            HomeNewsCarousel
        },
        data() {
            return {
                tl: null
            }
        },
        methods: {
            scrollReveal() {

                if (!this.isMobile) return;

                var speed = 0.85;
                var vw = $(window).width();

                this.tl = gsap.timeline({delay:0.2})
                this.tl.timeScale(1.8);


            }
        }
    }
</script>
