<template>
    <div class="scene scene--4" ref="scene">
        <div class="home__wrap__scene home__wrap__scene4">

            <!-- Kragos Image w/ vector -->
            <div class="kratos-drawing">
                <img src="/img/home/kratos2.png" class="kratos" alt = "An image of Kratos from the God of War franchise">
                <img src="/img/home/kratos-drawings.png" class="kratos-drawing" alt = "Drawings of Kratos from the God of War franchise">
            </div>

            <div class="our-culture" v-scroll-reveal.reset="{ distance: '0px', beforeReveal: revealTitle }">
                <h1 class="our-culture__header title-line1">OUR<br>CRAFT</h1>
                <h1 class="our-culture__subheading title-line2">YOUR<br>OPPORTUNITY</h1>
                <img src="../../assets/global/sms-logo--mark.svg" alt="Santa Monica Studios logo mark" class="our-culture__logo--mark title-logo-mark">
            </div>
            <div class="scene4__mond" v-scroll-reveal="{ distance: '0px', beforeReveal: scrollReveal, desktop: false }">
                <svg enable-background="new 0 0 782.3 738.5" viewBox="0 0 782.3 738.5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <clipPath id="scene4-mond-a">
                        <path d="m364.6 2c-62.5 0-119 25.3-160 66.3s-66.3 97.5-66.3 160v176.2 55.1h231.2c62.5 0 119-25.3 160-66.3s66.3-97.5 66.3-160v-176.2-55.1z" />
                    </clipPath>
                    <g clip-path="url(#scene4-mond-a)">
                        <image class="scene4__mond--image" height="800" overflow="visible" transform="matrix(.5724 0 0 .5724 138.2793 1.7907)" width="800" :xlink:href="data.ourCulture.image" />
                    </g>
                    <path class="scene4__mond--yellow" d="m138.3 598.2s-1.8 138.3 136.5 138.3v-138.3" fill="#e0c565" />
                    <path class="scene4__mond--red" d="m396.3 459.7c48.9-8.1 93.1-30.4 123.2-56.8 50.4-44.4 76.7-96.9 76.7-169.6v-231.5h127.1v231.4c0 62.6-25.2 119.1-65.9 160.2-40.8 41-96.9 66.3-159.1 66.3z" fill="#b22028" />
                    <!-- red -->
                    <g style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">
                        <path class="scene4__mond--bline" d="m139.2 459.5h641.1" />
                        <path class="scene4__mond--bcurve2" d="m138.3 459.5c138.3 0 136.5 138.3 136.5 138.3h-136.5" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                        <path class="scene4__mond--bcurve3" d="m138.3 459.5v138.3.3s-1.8 138.3 136.5 138.3v-138.2" />
                        <path class="scene4__mond--bcurve1" d="m138.5 597.8c-138.3 0-136.5-138.3-136.5-138.3h136.5" />
                        <path class="scene4__mond--cr1" d="m377.7 459.3h122.6c123.3 0 223.3-100 223.3-223.3v-233.8" />
                        <path class="scene4__mond--cr2" d="m595.8 2v55.1 176.2c0 62.5-25.3 119-66.3 160s-97.5 66.3-160 66.3h-231.2" />
                        <path class="scene4__mond--cl" d="m138.3 459.5v-55.1-176.1c0-62.5 25.3-119 66.3-160s97.5-66.3 160-66.3h359" />
                    </g>

                    <!-- tan -->
                    <g style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">
                        <path class="scene4__mond--bline" d="m139.2 459.5h641.1" />
                        <path class="scene4__mond--bcurve2" d="m138.3 459.5c138.3 0 136.5 138.3 136.5 138.3h-136.5" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                        <path class="scene4__mond--bcurve3" d="m138.3 459.5v138.3.3s-1.8 138.3 136.5 138.3v-138.2" />
                        <path class="scene4__mond--bcurve1" d="m138.5 597.8c-138.3 0-136.5-138.3-136.5-138.3h136.5" />
                        <path class="scene4__mond--cr1" d="m377.7 459.3h122.6c123.3 0 223.3-100 223.3-223.3v-233.8" />
                        <path class="scene4__mond--cr2" d="m595.8 2v55.1 176.2c0 62.5-25.3 119-66.3 160s-97.5 66.3-160 66.3h-231.2" />
                        <path class="scene4__mond--cl" d="m138.3 459.5v-55.1-176.1c0-62.5 25.3-119 66.3-160s97.5-66.3 160-66.3h359" />
                    </g>

                    <!-- black -->
                    <g style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">
                        <path class="scene4__mond--bline" d="m139.2 459.5h641.1" />
                        <path class="scene4__mond--bcurve2" d="m138.3 459.5c138.3 0 136.5 138.3 136.5 138.3h-136.5" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                        <path class="scene4__mond--bcurve3" d="m138.3 459.5v138.3.3s-1.8 138.3 136.5 138.3v-138.2" />
                        <path class="scene4__mond--bcurve1" d="m138.5 597.8c-138.3 0-136.5-138.3-136.5-138.3h136.5" />
                        <path class="scene4__mond--cr1" d="m377.7 459.3h122.6c123.3 0 223.3-100 223.3-223.3v-233.8" />
                        <path class="scene4__mond--cr2" d="m595.8 2v55.1 176.2c0 62.5-25.3 119-66.3 160s-97.5 66.3-160 66.3h-231.2" />
                        <path class="scene4__mond--cl" d="m138.3 459.5v-55.1-176.1c0-62.5 25.3-119 66.3-160s97.5-66.3 160-66.3h359" />
                    </g>
                </svg>

                <p class="home__wrap__scene4__copy" v-html="data.ourCulture.copy"></p>

                <div class="cta-btn mobile-only" v-scroll-reveal="{ desktop: false }">
                    <router-link to="/studio-life">
                        <div class="btn btn--red-outline">
                            <span>OUR STUDIO</span>
                        </div>
                    </router-link>
                </div>

            </div>
            <div class="scene4__right">
                <div class="scene4__right__quote" v-scroll-reveal="{ desktop: false }">

                </div>
                <div class="scene4__right__button">
                    <router-link to="/studio-life">
                        <diamond-button>OUR STUDIO</diamond-button>
                    </router-link>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
    import DiamondButton from '../buttons/DiamondButton';
    import HomeTitleReveal from '../../mixins/HomeTitleReveal.js'

    import gsap, {Power3} from 'gsap'
    import DrawSVGPlugin from "gsap/DrawSVGPlugin"
    gsap.registerPlugin(DrawSVGPlugin)

    export default {
        mixins:[HomeTitleReveal],
        props: ['data', 'isMobile'],
        components: {
            DiamondButton
        },
        data() {
            return {
                tl: null
            }
        },
        methods: {
            scrollReveal() {

                if (!this.isMobile) return;

                var speed = 0.85;
                var vw = $(window).width();

                this.tl = gsap.timeline({})
                this.tl.timeScale(1.8);

                var scene4MondStagger = 0.15;
                this.tl.timeScale(1.7);
                this.tl.fromTo($('.scene4__mond--cl'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene4MondStagger}, 0);
                this.tl.fromTo($('.scene4__mond--cr1'), 1.6, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeOut , stagger: scene4MondStagger}, 0.7);
                this.tl.fromTo($('.scene4__mond--cr2'), 1.55, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene4MondStagger}, 0);
                // this.tl.fromTo($('.scene4__mond--bline'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene4MondStagger}, 0);
                this.tl.fromTo($('.scene4__mond--bcurve1'), 2, { drawSVG: '100% 100%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene4MondStagger}, 0);
                this.tl.fromTo($('.scene4__mond--bcurve2'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene4MondStagger}, 0);
                this.tl.fromTo($('.scene4__mond--bcurve3'), 2, { drawSVG: '0% 0%' }, { drawSVG: '100%', ease: Power3.easeInOut , stagger: scene4MondStagger}, 0);
                this.tl.from($('.scene4__mond--red'), 2, { opacity: 0, ease: Power3.easeInOut, stagger:scene4MondStagger }, 1);
                this.tl.from($('.scene4__mond--yellow'), 2, { opacity: 0, ease: Power3.easeInOut, stagger:scene4MondStagger }, 1);
                this.tl.from($('.scene4__mond--image'), 2, { opacity: 0, scale: 0.7, transformOrigin: '50% 50%', ease: Power3.easeInOut, stagger:scene4MondStagger }, 1);

            }
        }
    }
</script>
