<template>
    <div class="scene scene--9" v-scroll-reveal="{ distance: '0px', beforeReveal: scrollReveal, desktop: false }">
        <div class="home__wrap__scene home__wrap__scene9">
            <site-footer />
        </div>
    </div>
</template>

<script>
    import SiteFooter from '../Footer';
    import gsap from 'gsap'
    export default {
        props: ['data', 'isMobile'],
        components: {
            SiteFooter
        },
        data() {
            return {
                tl: null
            }
        },
        methods: {
            scrollReveal() {

                if (!this.isMobile) return;

                var speed = 0.85;
                var vw = $(window).width();

                this.tl = gsap.timeline({delay:0.2})
                this.tl.timeScale(1.8);


            }
        }
    }
</script>
