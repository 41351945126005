<template>
    <div class="news-carousel">
        <div class="news-carousel__main">
            <div class = "slider">
                <div class = "slide" v-for="slide in data">
                    <router-link :to="`/stories/${slide.slug}`" :aria-label = "slide.title + ' link'">
                        <div class="nc__image" v-if="slide.asset.type == 'image'" :style="{ 'background-image': 'url(' + slide.asset.path + ')' }" role="img" :aria-label="slide.title + ' image'"></div>
                        <div class="nc__video" v-else>
                            <video autoplay muted loop playsinline>
                                <source type="video/mp4" :src="slide.asset.path">
                            </video>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="date-box">
                <div>
                    <div class="date-box__month">{{ curSlideData.date.month }}</div>
                    <div class="date-box__day">{{ curSlideData.date.day }}</div>
                    <div class="date-box__year">{{ curSlideData.date.year }}</div>
                </div>
            </div>
        </div>

        <div class="news-carousel__titles">
            <div class="news-carousel__titles__name">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
                {{ curSlideData.author }}
            </div>
            <div class="news-carousel__titles__title">
                <router-link :to="`/stories/${curSlideData.slug}`">
                    {{ curSlideData.title }}
                </router-link>
            </div>
            <div class="news-carousel__titles__btn">
                <router-link to="/stories">
                    <diamond-button>SEE ALL STORIES</diamond-button>
                </router-link>
            </div>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue'
    import DiamondButton from '../buttons/DiamondButton';
    import Slick from 'slick-carousel';

    export default {
        props: ['data'],
        components: {
            DiamondButton
        },
        data() {
            return {
                index: 0
            }
        },
        mounted() {
            if (this.data) {
                this.initNewsCarousel();
            }
        },
        methods: {
            initNewsCarousel() {
                $('.news-carousel__main .slider')
                    .slick({
                        speed: 350,
                        mobileFirst: true,
                        responsive: [{
                            breakpoint: 700,
                            settings: {
                                speed: 500
                            }
                        }]
                    })
                    .on('beforeChange', (event, slick, currentSlide, nextSlide) => {
                        this.index = nextSlide;
                    })
            }
        },
        computed: {
            curSlideData() {
                return this.data[this.index];
            }
        },
        watch: {
            data() {
                this.initNewsCarousel();
            }
        }
    }
</script>